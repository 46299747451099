import React from 'react'
import { Icon } from '@jarvis-catalyst/jarvis-ui-kit'
import { MenuOptions } from '../../store/modules/applications/types'

type Props = {
  item: MenuOptions
  currentPage: string
}

const ExpandIcon = function ({ item, currentPage }: Props) {
  const isCollapsed = item.submenu?.every((sub) => sub.hidden && sub.route !== currentPage)

  return (
    <button
      type="button"
      className="layout__submenu__button"
      onClick={() => item.submenu?.map((s) => (s.hidden = !isCollapsed))}
    >
      {isCollapsed ? (
        <Icon
          name="ChevronDown"
          size="small"
        />
      ) : (
        <Icon
          name="ChevronUp"
          size="small"
        />
      )}
    </button>
  )
}

export default ExpandIcon
