import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Icon, Typography } from '@jarvis-catalyst/jarvis-ui-kit'
import { Seller } from '../../../store/modules/sellers/types'
import SelectSellerModal from './SelectSellerModal'
import type { Tenant } from './types'
import { applicationSetConfigurationAction } from '../../../store/modules/application/actions'
import { TENANT_SITE_KEY } from '../../../utils/cache-store/types'
import { userPermissionsGetAction } from '../../../store/modules/user-permissions/actions'
import cacheStore from '../../../utils/cache-store'
import './SellerSelector.css'

type SellerSelectorProps = {
  init: boolean
  tenant: Tenant
}

const SellerSelector = function ({ init, tenant }: SellerSelectorProps) {
  const { t } = useTranslation()
  const history = useHistory()
  const { activeTenant } = useSelector((s: any) => s.application)
  const { data: dataSellers, loading, error } = useSelector((state: any) => state.sellers.getAll)
  const [showDialog, setShowDialog] = useState(false)
  const [sellerSelected, setSellerSelected] = React.useState<Seller[]>(
    activeTenant?.sellers ? activeTenant.sellers : [],
  )

  const getSelectedSeller = () => (activeTenant.sellers?.length ? activeTenant.sellers.map((e: Seller) => e.name) : [])
  const handleConfirmSeller = (sellers: Seller[]) => {
    if (init) {
      const newActiveTenant = { ...activeTenant, sellers }
      const query = new URLSearchParams(window.location.search)
      query.set('seller', sellerSelected[0]?.key || '')
      applicationSetConfigurationAction({
        key: 'activeTenant',
        value: newActiveTenant,
      })
      history.push({ search: query.toString() })
      cacheStore(TENANT_SITE_KEY).setData(newActiveTenant)
      userPermissionsGetAction()
      setShowDialog(false)
    }
  }

  return (
    <>
      {dataSellers.length === 0 && (
        <Button
          id="menu__dropdown-seller"
          className="screen-md-view-menu__dropdown menu__dropdown__btn"
          color="secondary"
          startIcon={
            loading ? (
              <Icon
                name="Loading"
                color="#7569f4"
                size="small"
              />
            ) : (
              <Icon
                name="ShoppingCart"
                color="#7569f4"
                size="small"
              />
            )
          }
          disabled
        >
          <Typography variant="H200">{t('MANAGE_SELLER.WITHOUT_SELLER')}</Typography>
        </Button>
      )}
      {dataSellers.length > 0 && (
        <Button
          id="menu__dropdown-seller"
          className="screen-md-view-menu__dropdown menu__dropdown__btn"
          startIcon={
            loading ? (
              <Icon
                name="Loading"
                color="#7569f4"
              />
            ) : (
              <Icon
                name="ShoppingCart"
                color="#7569f4"
                size="small"
              />
            )
          }
          endIcon={
            getSelectedSeller().length === 0 && (
              <Icon
                name="ChevronDown"
                color="#7569f4"
              />
            )
          }
          color="primary"
          variant="outlined"
          disabled={loading}
          onClick={() => !loading && setShowDialog(true)}
        >
          <Typography variant="H200">
            {getSelectedSeller().length > 0 ? getSelectedSeller().join(', ') : t('MANAGE_SELLER.SELLER_DEFAULT')}
          </Typography>
        </Button>
      )}
      {dataSellers.length > 0 && showDialog && (
        <SelectSellerModal
          data={dataSellers}
          error={error}
          sellerSelected={sellerSelected}
          setSellerSelected={setSellerSelected}
          tenant={tenant}
          onCancel={() => setShowDialog(false)}
          onConfirm={handleConfirmSeller}
          isOpen={showDialog}
        />
      )}
    </>
  )
}

export default SellerSelector
