import React from 'react'
import { Button, CardContent, CardActions, CardHeader, Divider, Box, Grid, Icon, Modal, Progress } from '@jarvis-catalyst/jarvis-ui-kit'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import './SelectSiteSellerModal.css'
import { Seller } from '../../../store/modules/sellers/types'
import ErrorNotification from '../../../components/errorNotification/ErrorNotification'
import { linkRequestAccessJarvis } from '../../dashboard/externalLinks'
import { SelectSellerModalProps } from './ManagerTenant'

export const isSelected = (sellerSelected: Seller[], e: Seller) =>
  sellerSelected.find((seller: Seller) => seller.id === e.id)

const SelectSellerModal = function ({
  data: dataSellers,
  error: errorGetAllSellers,
  tenant,
  onConfirm,
  onCancel,
  isOpen,
  sellerSelected,
  setSellerSelected,
}: SelectSellerModalProps) {
  const { t } = useTranslation()
  const { activeTenant } = useSelector((s: any) => s.application)
  const {
    data: roles,
    error: isErrorRolesBySites,
    loading: loadingRoles,
  } = useSelector((s: any) => s.instrospect.getRolesByAccountId)

  const handleConfirm = () => {
    onConfirm(sellerSelected)
  }

  const handleCancel = () => {
    setSellerSelected(activeTenant?.sellers || [])
    onCancel()
  }

  const wrapperHandleAddSeller = (seller: Seller) => {
    setSellerSelected([seller])
  }

  const wrapperHandleRemoveSeller = (seller: Seller) => {
    setSellerSelected(sellerSelected.filter((s: Seller) => s.id !== seller.id))
  }

  const isDisabled = (seller: any) => !roles.sellers.map((role: any) => role.id).includes(seller.id)

  return (
    <Modal open={isOpen}>
      <Box className="site-selector-modal">
        <CardHeader
          sx={{ padding: 0, paddingBottom: '24px' }}
          title={`${t('MANAGE_SELLER.SELECT_A_SELLER')} ${tenant.label}`}
        />
        <Divider sx={{ borderWidth: 0, margin: 0 }} />
        {errorGetAllSellers || isErrorRolesBySites ? (
          <Box sx={{ margin: '30px 0' }}>
            <ErrorNotification
              color="#42526E"
              title={t('MANAGE_SELLER.ERROR_LOADING_SELLERS_TITLE')}
              errorMessage={t('MANAGE_SELLER.ERROR_LOADING_SELLERS_MSG')}
            />
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleCancel}
              size="medium"
            >
              {t('ACTIONS.CLOSE')}
            </Button>
          </Box>
        ) : (
          <CardContent>
            {loadingRoles ? (<Progress />) : (
              dataSellers.map((seller: Seller) => (
                <React.Fragment key={seller.key}>
                  <span
                    onClick={function () {
                      return !isDisabled(seller) && (
                        isSelected(sellerSelected, seller)
                          ? wrapperHandleRemoveSeller(seller)
                          : wrapperHandleAddSeller(seller))
                    }}
                  >
                    <Grid columns={3}>
                      <span data-testid={seller.id}>
                        <div className={`site__${isDisabled(seller) ? 'inactive' : 'active'} site__column`}>
                          <Box className="site__icon-name-container">
                            <Icon
                              name={isSelected(sellerSelected, seller) ? 'Check' : 'ShoppingCart'}
                              color={isDisabled(seller) ? '#7569F4' : '#8B97AD'}
                              size="small"
                            />
                            <Box className={isSelected(sellerSelected, seller) ? 'checked' : ''}>
                              <div className="site__name">{seller.name}</div>
                              <div className="site__locked__description">{isDisabled(seller) && t('MANAGE_SITE.LOCKED_DESCRIPTION')}</div>
                            </Box>
                          </Box>
                          <Box>
                            {isDisabled(seller) && (
                              <a
                                href={linkRequestAccessJarvis}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon
                                  name="Lock"
                                  color="#7569F4"
                                  size="small"
                                  key="lock__icon"
                                />
                              </a>
                            )}
                          </Box>
                        </div>
                      </span>
                    </Grid>
                  </span>
                </React.Fragment>
              ))
            )}
          </CardContent>
        )}
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleCancel}
          >
            {t('ACTIONS.CANCEL')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleConfirm}
            disabled={errorGetAllSellers}
          >
            {t('ACTIONS.CONFIRM')}
          </Button>
        </CardActions>
      </Box>
    </Modal>
  )
}

export default SelectSellerModal
