import { Icon } from '@jarvis-catalyst/jarvis-ui-kit'
import { MenuOptions } from '../../../../store/modules/applications/types'
import { longNameTooltip } from './LongNameTooltip'
import { iconTooltip } from './IconTooltip'

export const makeItemInternal = (item: MenuOptions, name: string, uiSidebar: string) => {
  if (item.type === 'divider') {
    return <i />
  }

  if (uiSidebar === 'full') {
    return (
      <>
        {item.icon ? (
          <Icon
            name={item.icon}
            size="small"
          />
        ) : (
          <br />
        )}
        {longNameTooltip(name)}
      </>
    )
  }

  return (
    <div>
      {item.icon ? iconTooltip(item.icon, name) : <br />}
      {item.type !== 'item' && <span title={name}>{name}</span>}
    </div>
  )
}
