import React from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@jarvis-catalyst/jarvis-ui-kit'

type SidebarProps = {
  children: React.ReactNode
}

const Sidebar = function ({ children }: SidebarProps) {
  const { uiSidebar } = useSelector((s: any) => s.application)

  return uiSidebar === 'hidden' ? null : (
    <Box className={uiSidebar === 'full' ? 'layout__sidebar layout__sidebar__full' : 'layout__sidebar'}>
      <div id="layout__sidebar">
        {children}
        <span className="layout-modal-overlay" />
      </div>
    </Box>
  )
}

export default Sidebar
