import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Box, Breadcrumbs, Button, Card, Container, Typography } from '@jarvis-catalyst/jarvis-ui-kit'
import { ROUTES } from '../../../../../const'
import useAppendQS from '../../../../../hooks/useAppendQS'
import './ManageUser.css'
import CardSubscriptionRoles from './CardSubscriptionRoles'
import CardDetail from './CardDetail'
import CardRoles from './CardRoles'
import CustomTitleAndTooltip from '../../../../../components/customTitleAndTooltip/CustomTitleAndTooltip'
import { Account, ManageUserFormProps, TenantRole } from './manage-user.type'

const ManageUserForm = function ({ isLoading, callSave, hasWrite, pageTitle, user, init }: ManageUserFormProps) {
  const { t } = useTranslation()
  const history = useHistory()
  const appendQS = useAppendQS()

  const defaultTenantRoles: TenantRole[] = [
    {
      id: { label: '', value: '' },
      roles: [],
      sites: [],
      siteRoles: { id: [], roles: [] },
      sellers: [],
      sellerRoles: { id: [], roles: [] },
    },
  ]

  const form = useForm<Account>({
    defaultValues: {
      tenantRoles: defaultTenantRoles,
    },
    mode: 'onChange',
  })

  const { handleSubmit, watch } = form
  const tenantRoles = watch('tenantRoles')

  const hasValidTenantRole = (): boolean =>
    tenantRoles.some((role) => role.id.value !== '' && (role.roles.length > 0 || role.sites.length > 0))

  const handleSave = handleSubmit((newUser: Account) => callSave(newUser))

  return (
    <div style={{ padding: 20 }}>
      <Container maxWidth="xl">
        <Card variant="elevation">
          <div style={{ marginLeft: 15, marginTop: 4 }}>
            <Breadcrumbs>
              <Link
                style={{ textDecoration: 'none', padding: 0 }}
                to={{ pathname: '/' }}
              >
                <Typography
                  color="#a7a7a7"
                  variant="h4"
                >
                  {t('HOME')}
                </Typography>
              </Link>
              <Link
                style={{ textDecoration: 'none', padding: 0 }}
                to={{ pathname: '/users' }}
              >
                <Typography
                  color="#a7a7a7"
                  variant="h4"
                >
                  {t('USERS')}
                </Typography>
              </Link>
              <Link
                style={{ textDecoration: 'none', padding: 0 }}
                to="#"
              >
                <Typography
                  color="primary"
                  variant="h4"
                >
                  {pageTitle}
                </Typography>
              </Link>
            </Breadcrumbs>
            <div style={{ marginLeft: -15 }}>
              <CustomTitleAndTooltip
                title={pageTitle}
                tooltipTitle="MANAGE_USER.USER_INFORMATION"
              />
            </div>
          </div>
          <form onSubmit={handleSave}>
            <Box sx={{ padding: '16px 16px 24px' }}>
              <CardDetail
                user={user}
                form={form}
                init={init}
              />
              <CardSubscriptionRoles
                user={user}
                hasWrite={hasWrite}
                form={form}
                init={init}
              />
              <CardRoles
                init={init}
                form={form}
                user={user}
                defaultTenantRoles={defaultTenantRoles}
                hasWrite={hasWrite}
              />
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  disabled={isLoading || !hasWrite}
                  onClick={() => history.push(appendQS(ROUTES.USER_LIST))}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  disabled={isLoading || !hasWrite || !hasValidTenantRole()}
                >
                  {t('SAVE')}
                </Button>
              </Box>
            </Box>
          </form>
        </Card>
      </Container>
    </div>
  )
}

export default ManageUserForm
