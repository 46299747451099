import { Icon, Tooltip, Typography } from '@jarvis-catalyst/jarvis-ui-kit'
import { IconType } from '../../../../store/modules/applications/types'

export const iconTooltip = (icon: IconType, name: string) => (
  <Tooltip
    title={
      <Typography
        variant="H200"
        color="#5f6b81"
        gutterBottom
      >
        {name}
      </Typography>
    }
    placement="left"
  >
    <Icon
      name={icon}
      size="small"
    />
  </Tooltip>
)
