/* eslint-disable react/jsx-curly-newline */
/* eslint-disable operator-linebreak */
import React, { useState } from 'react'
import {
  Modal,
  Alert,
  Button,
  Box,
  CardContent,
  CardHeader,
  Progress,
  Icon,
  Grid,
  Tooltip,
} from '@jarvis-catalyst/jarvis-ui-kit'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Site } from '../../../store/modules/sites/types'
import './SelectSiteSellerModal.css'
import { linkRequestAccessJarvis } from '../../dashboard/externalLinks'
import ErrorNotification from '../../../components/errorNotification/ErrorNotification'

interface SelectSiteModalProps {
  dataSites: {
    data: Site[]
    error: boolean
  }
  tenant: { label: string }
  onConfirm: (e: Site[]) => void
  onCancel: () => void
  isRetailUser: boolean
  userSites: Site[]
}

const getSitesByUrl = (dataSites: Site[], userSites: Site[]) => {
  const query = new URLSearchParams(window.location.search)
  let sites: string[] = []
  const qSites = query.get('sites')
  if (qSites != null) {
    sites = decodeURIComponent(qSites).split(',')
  }
  return dataSites.filter((e: Site) => userSites.find((s) => s.key === e.key) && sites.includes(e.key))
}

export const isSelected = (sitesSelected: Site[], e: Site) => sitesSelected.find((site: Site) => site.id === e.id)

const SelectSiteModal = function ({
  dataSites,
  tenant,
  onConfirm,
  onCancel,
  isRetailUser,
  userSites,
}: SelectSiteModalProps) {
  const { t } = useTranslation()
  const { activeTenant } = useSelector((s: any) => s.application)
  const [sitesSelected, setSiteSelected] = useState<Site[]>(getSitesByUrl(activeTenant.sites, userSites))
  const {
    data: roles,
    error: isErrorRolesBySites,
    loading: loadingRoles,
  } = useSelector((s: any) => s.instrospect.getRolesByAccountId)
  const [showAlarm, setShowAlarm] = useState(false)
  const { error: isErrorGetAllSites } = dataSites

  const handleConfirm = () => {
    if (isRetailUser && sitesSelected.length === 0) {
      setShowAlarm(true)
    } else {
      onConfirm(sitesSelected)
    }
  }
  const handleCancel = () => {
    setSiteSelected(activeTenant?.sites || [])
    onCancel()
  }

  const wrapperHandleAddSite = (site: Site) => {
    delete site.isAllowed
    setSiteSelected([site])
    setShowAlarm(false)
  }

  const wrapperHandleRemoveSite = (site: Site) => {
    setSiteSelected(sitesSelected.filter((s: Site) => s.id !== site.id))
  }

  const parsedSites = (sites: Site[]) => {
    const userSites = roles.sites ? roles.sites.map((site: any) => site.id) : []
    const sitesMapped: Site[] = sites.map((site) => ({
      ...site,
      isAllowed: site.id ? userSites.includes(site.id) : false,
    }))
    return sitesMapped
  }

  const getIcon = (site: Site) => (
    <Icon
      name={isSelected(sitesSelected, site) ? 'Check' : 'Site'}
      color={site.isAllowed ? '#7569F4' : '#8B97AD'}
      size="medium"
    />
  )

  return (
    <Modal open>
      <div className="site-selector-modal">
        <CardHeader
          title={`${t('MANAGE_SITE.SELECT_A_SITES')} ${tenant.label}`}
          titleTypographyProps={{ variant: 'H800' }}
        />
        {showAlarm && (
          <Alert
            color="warning"
            onClose={() => setShowAlarm(false)}
            severity="warning"
          >
            {t('MANAGE_SITE.SELECT_AT_LEAST_ONE_SITE')}
          </Alert>
        )}
        {isErrorRolesBySites || isErrorGetAllSites ? (
          <div className="error">
            <ErrorNotification
              color="#42526E"
              title={t('MANAGE_SITE.ERROR_LOADING_ROLES_TITLE')}
              errorMessage={t('MANAGE_SITE.ERROR_LOADING_ROLES_MESSAGE')}
            />
            <Button
              color="secondary"
              onClick={handleCancel}
              size="medium"
              variant="outlined"
            >
              {t('ACTIONS.CLOSE')}
            </Button>
          </div>
        ) : (
          <>
            <CardContent>
              {loadingRoles ? (
                <div data-testid="loadingRoles">
                  <Progress circle />
                </div>
              ) : (
                <Grid
                  container
                  direction="column"
                >
                  {parsedSites(dataSites.data).map((site: Site) => (
                    <React.Fragment key={site.key}>
                      <span
                        onClick={() =>
                          site.isAllowed &&
                          (isSelected(sitesSelected, site) ? wrapperHandleRemoveSite(site) : wrapperHandleAddSite(site))
                        }
                      >
                        <Grid item>
                          <span data-testid={site.id}>
                            <Box
                              className={`site__${site.isAllowed ? 'active' : 'inactive'} ${
                                isSelected(sitesSelected, site) ? 'site_selected' : ''
                              }`}
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              {getIcon(site)}
                              <Box
                                className={isSelected(sitesSelected, site) ? 'checked' : ''}
                                sx={{
                                  ...(isSelected(sitesSelected, site) && {
                                    fontWeight: 'bold',
                                    fontSize: '15px',
                                  }),
                                }}
                              >
                                <Box sx={{ fontWeight: 'bold', fontSize: '15px' }}>{site.name}</Box>
                                <Box className="description">{site.description}</Box>
                              </Box>
                              {!site.isAllowed && (
                                <Box sx={{ marginLeft: 'auto' }}>
                                  <a
                                    href={linkRequestAccessJarvis}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <Tooltip
                                      title={t('MANAGE_SITE.LOCKED_DESCRIPTION')}
                                      placement="left"
                                    >
                                      <Icon
                                        name="Lock"
                                        color="#7569F4"
                                        size="small"
                                        data-testid="lock__icon"
                                      />
                                    </Tooltip>
                                  </a>
                                </Box>
                              )}
                            </Box>
                          </span>
                        </Grid>
                      </span>
                    </React.Fragment>
                  ))}
                </Grid>
              )}
            </CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
              <Button
                color="primary"
                onClick={handleCancel}
                disabled={isRetailUser}
                variant="outlined"
              >
                {t('ACTIONS.CANCEL')}
              </Button>
              <Button
                color="primary"
                onClick={handleConfirm}
                disabled={!roles?.sites || roles.sites.length === 0}
                variant="contained"
              >
                {t('ACTIONS.CONFIRM')}
              </Button>
            </Box>
          </>
        )}
      </div>
    </Modal>
  )
}

export default SelectSiteModal
