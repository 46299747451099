import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Table,
  Pagination,
  Chip,
  Icon,
  ButtonGroup,
  IconButton,
  Grid,
  Menu,
  Button,
  Typography,
  Box,
} from '@jarvis-catalyst/jarvis-ui-kit'
import useLanguageAndTenantId from '../../../../hooks/useLanguageAndTenantId'
import { sendDangerNotification, sendSuccessNotification } from '../../../../utils/sendNotification'
import { ROUTES } from '../../../../const'
import useAppendQS from '../../../../hooks/useAppendQS'
import { applicationDeleteAction, applicationDeleteResetAction, applicationsGetAllAction, tenantApplicationsGetAllAction } from '../../../../store/modules/applications/actions'
import { Option } from './ApplicationsListing'
import { Application, Group } from '../../../../store/modules/applications/types'
import './ApplicationsListing.css'

const appState = {
  GREEN: '#36B37E',
  GRAY: '#6B778C',
}

type ApplicationListingTableProps = {
  hasWrite: boolean,
  selectedTenant: string | undefined,
  selectedAppType?: Option
}

const ApplicationListingTable = function ({ hasWrite, selectedTenant, selectedAppType }: ApplicationListingTableProps) {
  const { t } = useTranslation()
  const { language } = useLanguageAndTenantId()
  const { data, pagination, error, loading, success } = useSelector((s: any) => (
    selectedAppType?.tenantDependent
      ? s.applications.getAllTenant
      : s.applications.getAll))
  const deleteAppStore = useSelector((s: any) => s.applications.delete)
  const [currentPage, setCurrentPage] = useState(1)
  const [didInit, setDidInit] = useState<boolean>(false)
  const history = useHistory()
  const appendQs = useAppendQS()

  const getAllApps = useCallback((page: number) => {
    (selectedTenant && selectedAppType?.tenantDependent)
      ? tenantApplicationsGetAllAction(selectedTenant, { limit: 10, page })
      : applicationsGetAllAction({ limit: 10, page, globalApps: true })
  }, [selectedAppType, selectedTenant])

  useEffect(() => {
    getAllApps(currentPage)
  }, [currentPage])

  useEffect(() => {
    if (!didInit) {
      setDidInit(true)
    } else {
      currentPage === 1 ? getAllApps(1) : setCurrentPage(1)
    }
  }, [selectedTenant, selectedAppType])

  useEffect(() => {
    if (!deleteAppStore.loading && deleteAppStore.success) {
      applicationDeleteResetAction()
      sendSuccessNotification(t('APPLICATIONS_DELETED'))
      currentPage === 1 ? getAllApps(currentPage) : setCurrentPage(1)
    }
    if (deleteAppStore.error && !deleteAppStore.loading) {
      applicationDeleteResetAction()
      sendDangerNotification(t(`ACTIONS.${deleteAppStore.errorMessage}`))
    }
  }, [deleteAppStore])

  const handleDelete = (id: string) => applicationDeleteAction(id)
  const handleCopy = (id: string) => navigator.clipboard.writeText(id)
  const getState = ({ isActive }: Application) => {
    const color = isActive ? appState.GREEN : appState.GRAY
    return (<Icon
      name="Circle"
      color={color}
      bgcolor={color}
      size="small"
    />)
  }
  const getId = ({ id }: Application) => (<IconButton
    name="Copy"
    onClick={() => handleCopy(id)}
  />)

  const handleEdit = (app: Application) => {
    history.push(appendQs(`${ROUTES.APPLICATION_MANAGE}/${app.id}`))
  }

  const options = (app: Application) => {
    const contents = [
      { content: t('DELETE_WARNING'), icon: 'Warning', key: '0', disabled: true },
      {
        content: (
          <Grid spacing={1}>
            <Button variant="text">
              {t('CANCEL')}
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={() => handleDelete(app.id)}
            >
              {t('CONFIRM')}
            </Button>
          </Grid>
        ),
        key: '1',
      },
    ]
    return (
      <div className="text-right">
        <ButtonGroup size="small">
          <IconButton
            name="Edit"
            label={t('EDIT')}
            disabled={!hasWrite}
            onClick={() => {
              handleEdit(app)
            }}
          />
          <Menu
            AnchorComponent={IconButton}
            AnchorComponentProps={{ label: 'Delete', name: 'Delete' }}
            contents={contents as any}
          />
        </ButtonGroup>
      </div>
    )
  }

  const headers = [
    { key: 'appId', content: <Typography variant="H500">{t('ID')}</Typography> },
    { key: 'name', content: <Typography variant="H500">{t('NAME')}</Typography> },
    { key: 'group', content: <Typography variant="H500">{t('GROUP')}</Typography> },
    { key: 'createdAt', content: <Typography variant="H500">{t('DATE')}</Typography> },
    { key: 'state', content: <Typography variant="H500">{t('STATE')}</Typography> },
    { key: 'actions', content: '' },
  ]

  const rows = error ? [] : data.map((app: Application) => {
    const date = new Date(app.createdAt).toLocaleDateString(language)
    const time = new Date(app.createdAt).toLocaleTimeString(language)
    const fullDate = `${date} ${time}`
    const badge = (group: Group) => <Chip
      key={group.id}
      label={group.name}
    />

    return {
      id: app.id,
      appId: getId(app),
      name: <Typography variant="H400">{app.name}</Typography>,
      group: app.groups.map(badge),
      createdAt: <Typography variant="H400">{fullDate}</Typography>,
      state: getState(app),
      actions: options(app),
    }
  })

  return (
    <Box sx={{ marginTop: 3.5 }}>
      <Table
        columns={headers}
        rows={rows}
        loading={loading || !didInit}
        emptyMessage={error ? error.message : t('NO_RESULTS_FOUND')}
      />
      {(success && data.length > 0) && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: 2,
          }}
        >
          <Pagination
            color="primary"
            currentpage={currentPage}
            totalpages={pagination?.totalPages}
            onChange={(event, page) => setCurrentPage(page)}
          />
        </Box>
      )}
    </Box>
  )
}

export default ApplicationListingTable
