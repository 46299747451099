import { ParamsGetAllAction } from '../../types'

export type Application = {
  id: string
  name: string
  description: {
    en: string
    es: string
  }
  navigationType: NAVIGATION
  url: string
  route: string
  isActive: boolean
  initAppWithRoute: boolean
  metadata: {
    contactInfo: Contact[]
  }
  permissions: string[]
  menu: Menu
  groups: Group[]
  createdAt: string
  updatedAt: string
}
export type MetadataPayloadType = {
  metadata: {
    contactInfo: Contact[]
  }
}

export type ApplicationPayload = MetadataPayloadType & {
  id?: string
  name: string
  description: {
    en: string
    es: string
  }
  navigationType: NAVIGATION
  url: string
  route: string
  isActive: boolean
  initAppWithRoute: boolean
  permissions: string[]
  menu: Menu
  groups: Group[]
}

export enum NAVIGATION {
  Iframe = 'IFRAME',
  Internal = 'INTERNAL',
  External = 'EXTERNAL',
}

export interface Contact {
  region: string
  name: string
  email: string
  channel: string
}

export type IconType =
  | 'API'
  | 'App'
  | 'ArrowDown'
  | 'ArrowLeft'
  | 'Arrows'
  | 'Assign'
  | 'Audit'
  | 'Bell'
  | 'Calendar'
  | 'Catalog'
  | 'Cheap'
  | 'Check'
  | 'CheckCircle'
  | 'ChevronDown'
  | 'ChevronLeft'
  | 'ChevronRight'
  | 'ChevronUp'
  | 'Circle'
  | 'CirclePlus'
  | 'ClickAndCollect'
  | 'Clock'
  | 'Close'
  | 'CloseSmall'
  | 'Confluence'
  | 'Content'
  | 'Copy'
  | 'Delete'
  | 'DeliveryTruck'
  | 'Denied'
  | 'Download'
  | 'Edit'
  | 'ErrorCircle'
  | 'Excel'
  | 'Expensive'
  | 'Export'
  | 'ExternalLink'
  | 'Feedback'
  | 'File'
  | 'Help'
  | 'Home'
  | 'Info'
  | 'Jarvis'
  | 'Light'
  | 'Loading'
  | 'Lock'
  | 'MenuBurger'
  | 'Minus'
  | 'Plus'
  | 'Price'
  | 'ProjectManagement'
  | 'Promotion'
  | 'RBAC'
  | 'Search'
  | 'Settings'
  | 'ShoppingCart'
  | 'Site'
  | 'SoldOut'
  | 'Star'
  | 'Template'
  | 'User'
  | 'UserManagement'
  | 'View'
  | 'Warning'

interface Menu {
  name: {
    en: string
    es: string
  }
  route: string
  permissions: string[]
  icon: IconType
  submenu: Submenu
}

export type MenuItem = {
  name: {
    en: string
    es: string
  }
  route: string
  permissions: string[]
  icon?: IconType
  index?: number
}

type Submenu = MenuItem[]

export interface Group {
  id: string
  name: string
}

export type Tenant = {
  id: string
  name: string
}

export type ParamsGetAllApps = {
  groupId?: string
  globalApps?: boolean
} & ParamsGetAllAction

export type LocaleString = {
  en: string
  es: string
}

export type MenuOptions = {
  id?: string
  icon?: IconType
  isActive?: boolean
  route?: string
  name?: string | LocaleString
  url?: string
  type: 'divider' | 'item' | 'label' | 'labelMenu' | 'click'
  hidden?: boolean
  submenu?: Array<MenuOptions>
  description?: string | LocaleString
  initAppWithRoute?: boolean
  navigationType?: NAVIGATION
  metadata?: { contactInfo: Contact[] }
  groups?: any[]
  appType?: string
  microApplication?: boolean
  onClick?: () => void
}

export type SidebarMenu = {
  add: (newItem: MenuOptions) => void
  build: () => MenuOptions[]
  reset: () => void
}
