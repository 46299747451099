import { ROUTE_PREFIX, DEFAULT_URI_START_APP } from '../../../const'
import { Permissions } from '../../../hooks/useAppPermissions'
import { Application, MenuItem, MenuOptions, SidebarMenu } from '../../../store/modules/applications/types'

const menu = () => {
  let newMenu: MenuOptions[] = []
  const build = () => newMenu

  const add = (newItem: MenuOptions) => {
    if (newItem.type === 'item' && newItem.route) {
      if (!newMenu.find((item: MenuOptions) => item.type === 'item' && item.route === newItem.route)) {
        newMenu.push(newItem)
        newItem.submenu?.map((sub) => newMenu.push(sub))
      }
    } else {
      newMenu.push(newItem)
      newItem.submenu?.map((sub) => newMenu.push(sub))
    }
  }

  const reset = () => {
    newMenu = []
  }

  return {
    reset,
    add,
    build,
  }
}

export const addAppsHeader = (menu: SidebarMenu) => {
  menu.add({
    id: 'customApps',
    name: 'INTEGRATED_APPS',
    type: 'label',
  })
  return menu
}

export const addHome = (menu: SidebarMenu) => {
  menu.add({
    id: 'globalApps',
    name: 'GLOBAL_APPS.TITLE',
    type: 'label',
  })
  menu.add({
    id: 'Home',
    route: DEFAULT_URI_START_APP,
    url: '',
    icon: 'Home',
    name: 'HOME',
    isActive: true,
    type: 'item',
  })
  return menu
}

export const addApps = (menu: SidebarMenu, apps: Application[], permissions: Permissions, language: 'en' | 'es') => {
  const type = 'item'

  const getRoute = (route: string) => `${ROUTE_PREFIX}${route}`

  const getActive = (isActive: boolean, menu: MenuItem) => isActive && permissions.customApp(menu)

  const compareName = (nameA: Application, nameB: Application) =>
    nameA.menu.name[language].localeCompare(nameB.menu.name[language])

  const compareIndex = (menuA: MenuItem, menuB: MenuItem) => (menuA.index || 0) - (menuB.index || 0)

  apps.sort(compareName).map((app) =>
    menu.add({
      ...app,
      ...app.menu,
      type,
      icon: app.menu.icon,
      route: getRoute(app.menu.route),
      isActive: getActive(app.isActive, app.menu),
      microApplication: true,
      submenu: app.menu.submenu.sort(compareIndex).map((sub: MenuItem) => ({
        ...sub,
        type,
        route: getRoute(`${app.menu.route}${sub.route}`),
        isActive: getActive(app.isActive, sub),
        hidden: true,
        icon: undefined,
      })),
    }),
  )

  return menu
}

export default menu()
