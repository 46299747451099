import React from 'react'
import { Box, Chip } from '@jarvis-catalyst/jarvis-ui-kit'
import config from '../../shell/config'

import './Header.css'

const EnvironmentIdentifier = function () {
  const env = config.REACT_APP_ENVIRONMENT?.toUpperCase()

  return env ? (
    <Box sx={{ px: 2 }}>
      <Chip
        label={env}
        sx={{ backgroundColor: '#837FF2', color: 'white' }}
        variant="outlined"
      />
    </Box>
  ) : (
    <span />
  )
}

export default EnvironmentIdentifier
