import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardContent, Card } from '@jarvis-catalyst/jarvis-ui-kit'

import TenantsListing from '../../components/tenantsListing/TenantsListing'
import DeleteTenantModal from '../../components/deleteTenantModal/index'

import './Tenants.css'

import useDocumentTitle from '../../../../hooks/useDocumentTitle'
import { Tenant } from '../../tenant.type'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { TitleTenant } from '../../components/Title'
import { useTenants } from '../../hook/useTenants'
import TenantModal from '../../components/tenantModal'

const Tenants = function () {
  const { t } = useTranslation()
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [selectedTenant, setSelectedTenant] = useState<Tenant>({ id: '', description: '', name: '' })
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const { createTenant, deleteTenant, hasWrite, updateTenant } = useTenants()
  useDocumentTitle(t('MANAGE_TENANTS'))

  return (
    <section style={{ padding: 20 }}>
      <Card variant="elevation">
        <Breadcrumbs t={t} />
        <CardContent>
          <TitleTenant t={t} />
        </CardContent>
        <CardContent>
          {isAddModalOpen && (
            <TenantModal
              hasWrite={hasWrite}
              heading={t('CREATE_TENANT_HEADING')}
              isModalOpen={isAddModalOpen}
              handleModalClose={() => setIsAddModalOpen(false)}
              handleSaveClick={createTenant}
              tenant={selectedTenant}
            />
          )}
          <TenantsListing
            hasWrite={hasWrite}
            setIsAddModalOpen={setIsAddModalOpen}
            handleDelete={(tenant: Tenant) => {
              setSelectedTenant(tenant)
              setIsDeleteModalOpen(true)
            }}
            handleEdit={(tenant: Tenant) => {
              setSelectedTenant(tenant)
              setIsEditModalOpen(true)
            }}
          />
          {isEditModalOpen && (
            <TenantModal
              heading={t('EDIT_TENANT_HEADING')}
              tenant={selectedTenant}
              isModalOpen={isEditModalOpen}
              handleModalClose={() => setIsEditModalOpen(false)}
              handleSaveClick={updateTenant}
              hasWrite={hasWrite}
            />
          )}
          {isDeleteModalOpen && (
            <DeleteTenantModal
              tenant={selectedTenant}
              isModalOpen={isDeleteModalOpen}
              handleModalClose={() => setIsDeleteModalOpen(false)}
              handleSaveClick={deleteTenant}
            />
          )}
        </CardContent>
      </Card>
    </section>
  )
}

export default Tenants
