import { Tooltip, Typography } from '@jarvis-catalyst/jarvis-ui-kit'

export const longNameTooltip = (name: string) => {
  const content = <span title="">{name}</span>
  return name?.length >= 20 ? (
    <Tooltip
      placement="left"
      title={
        <Typography
          variant="H200"
          color="#5f6b81"
          gutterBottom
        >
          {name}
        </Typography>
      }
    >
      {content}
    </Tooltip>
  ) : (
    content
  )
}
