import React, { useEffect } from 'react'
import { FloatingActionButton, ButtonGroup, Alert, Progress, Select, TextField } from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'
import { useFieldArray, Controller, FieldErrors } from 'react-hook-form'
import { UseFormReturn } from 'react-hook-form/dist/types/form'
import { regexEmail, regexUrl } from '../../../../utils/validations'
import './ContactInfo.css'
import CardBlock from '../../../../components/CardBlock/CardBlock'
import { ApplicationPayload, Contact, MetadataPayloadType } from '../../../../store/modules/applications/types'

type ContactInfoProps = {
  contacts?: Contact[]
  form: UseFormReturn<ApplicationPayload>
  init: boolean
}

export const ContactInfo = function ({ contacts, form, init }: ContactInfoProps) {
  const { t } = useTranslation()

  const regions = [
    { value: 'latam', label: 'LATAM' },
    { value: 'india', label: 'India' },
  ]

  const { control, watch, setValue, formState: { errors } } = form

  useEffect(() => {
    setValue('metadata.contactInfo', contacts || [])
  }, [contacts])

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'metadata.contactInfo',
  })
  const contactInfo = watch('metadata.contactInfo')

  const handleContactRemove = (i: number) => () => remove(i)

  const addContact = () => append({ region: '', name: '', email: '', channel: '' })

  const error = (errors: FieldErrors<MetadataPayloadType>, i: number, name: string) => {
    if (Object.keys(errors).length === 0) return false
    const fieldError = errors?.metadata?.contactInfo?.[i]
    if (!fieldError) return false
    return fieldError[name as keyof typeof fieldError]
  }

  const errorHint = (errors: Record<string, any>, i: number, name: string) => {
    const fieldError = errors?.metadata?.contactInfo?.[i]?.[name]
    if (fieldError) return fieldError.type === 'pattern' ? t(`HINT_INVALID_${name.toUpperCase()}`) : t('HINT_REQUIRED_FIELD')
    return ''
  }

  const renderInput = (i: number, name: string, placeHolder: string, defaultValue: string, pattern?: RegExp) => (
    <Controller
      name={`metadata.contactInfo.${i}.${name}` as any}
      defaultValue={defaultValue}
      control={control}
      rules={{ required: true, pattern }}
      render={({ field: { onChange, value, ref } }) => (
        <TextField
          fullWidth
          error={!!error(errors, i, name)}
          value={value}
          inputRef={ref}
          onChange={onChange}
          helperText={errorHint(errors, i, name)}
          placeholder={t(`MANAGE_CUSTOM_APPLICATION.${placeHolder}`)}
        />
      )}
    />
  )

  return (
    <CardBlock title={t('MANAGE_CUSTOM_APPLICATION.CONTACT_INFO')}>
      <div style={{ marginBottom: 15 }}>
        <Alert color="info">{t('MANAGE_CUSTOM_APPLICATION.INFO')}</Alert>
      </div>
      {init ? (
        <table style={{ width: '100%', borderSpacing: '8px' }}>
          <thead style={{ textAlign: 'left', marginBottom: '10px' }}>
            <tr>
              <th>{t('REGION')}</th>
              <th>{t('NAME')}</th>
              <th>{t('EMAIL')}</th>
              <th>{t('MANAGE_CUSTOM_APPLICATION.CHANNEL')}</th>
            </tr>
          </thead>
          {fields.map((contact: any, i: number) => (
            <tbody key={`body-${i}`}>
              <tr key={`${contact.region}-${contact.email}`}>
                <td>
                  <Controller
                    name={`metadata.contactInfo.${i}.region`}
                    control={control}
                    defaultValue={contactInfo[i]?.region}
                    rules={{ required: true }}
                    render={({ field: { value, onChange }, formState: { errors } }) => (
                      <div>
                        <Select
                          labelId="region-selector"
                          autoWidth
                          options={regions}
                          value={value.toLowerCase()}
                          label={t('MANAGE_CUSTOM_APPLICATION.SELECT_REGION')}
                          helperText={error(errors, i, 'region') ? t('HINT_REQUIRED_FIELD') : ''}
                          onChange={onChange}
                          error={!!error(errors, i, 'region')}
                        />
                      </div>
                    )}
                  />
                </td>
                <td>{renderInput(i, 'name', 'CONTACT_NAME_PLACEHOLDER', contactInfo[i]?.name)}</td>
                <td>{renderInput(i, 'email', 'CONTACT_EMAIL_PLACEHOLDER', contactInfo[i]?.email, regexEmail)}</td>
                <td>{renderInput(i, 'channel', 'CONTACT_SLACK_PLACEHOLDER', contactInfo[i]?.channel, regexUrl)}</td>
                <td className="text-right">
                  <ButtonGroup>
                    {i === 0 ? (
                      <FloatingActionButton
                        color="info"
                        name="Plus"
                        onClick={addContact}
                        size="small"
                      />
                    ) : (
                      <FloatingActionButton
                        color="info"
                        name="Delete"
                        onClick={handleContactRemove(i)}
                        size="small"
                      />
                    )}
                  </ButtonGroup>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      ) : (
        <Progress
          circle
          color="secondary"
        />
      )}
    </CardBlock>
  )
}
