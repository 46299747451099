import React from 'react'
import { Box, Button, Icon, Typography } from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'
import { CHAT_LINK, JARVIS_DOCUMENTATION } from '../../dashboard.constants'

const ChatOurTeam = function () {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        padding: '25px',
        border: '1px solid #f0f0f0',
        borderRadius: '8px',
        backgroundColor: '#fafafa',
        marginBottom: '100px',
        marginTop: '100px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '70px',
            height: '70px',
            backgroundColor: '#eef1ff',
            borderRadius: '8px',
            marginRight: '16px',
          }}
        />
        <Box sx={{ padding: 2 }}>
          <Typography variant="h2">{t('STILL_HAVE_QUESTIONS')}</Typography>
          <Typography variant="h3">
            {t('CANT_FIND_ANSWER')}
            <a
              style={{
                padding: 0,
                color: 'rgba(117, 105, 244, 1)',
                cursor: 'pointer',
                textDecoration: 'none',
                marginLeft: '5px',
              }}
              href={CHAT_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('CHAT_OUR_TEAM')}
            </a>
          </Typography>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="primary"
        endIcon={<Icon name="ExternalLink" />}
        href={JARVIS_DOCUMENTATION}
      >
        {t('DOCUMENTATION')}
      </Button>
    </Box>
  )
}

export default ChatOurTeam
