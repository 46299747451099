import { useSelector } from 'react-redux'
import useQuery from '../../../hooks/useQuery'
import { TenantProps } from '../../shell/modules/mainRouter.types'

const useTenants = () => {
  const query = useQuery()
  const { token } = useSelector((s: any) => s.application)
  const { getMyTenants: myTenants } = useSelector((s: any) => s.tenants)
  const currentTenant : TenantProps = myTenants.data.find((tenant: TenantProps) => tenant.id === query.get('tenant'))

  return {
    selectedTenant: currentTenant,
    token,
    myTenants,
  }
}

export default useTenants
