import React from 'react'
import LinkWrapper from './utils/LinkWrapper'
import useAppendQS from '../../hooks/useAppendQS'
import { MenuOptions } from '../../store/modules/applications/types'

type AppLinkProps = {
  item: MenuOptions
  children: React.ReactNode
}

const AppLink = function ({ item, children }: AppLinkProps) {
  const appendQS = useAppendQS()

  return item.navigationType === 'EXTERNAL' ? (
    <a
      href={item.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ) : (
    <LinkWrapper
      to={appendQS(`${item.route}`)}
      onClick={item.onClick}
    >
      {children}
    </LinkWrapper>
  )
}

export default AppLink
