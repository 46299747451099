import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Grid, Select, Button, Typography, Icon } from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'
import { dataIsLoad } from '../../../store/utils'
import getData from './getData'
import useTenants from './useTenants'
import cacheStore from '../../../utils/cache-store'
import { TENANT_SITE_KEY } from '../../../utils/cache-store/types'
import './TenantSelector.css'
import { sellersGetAllAction } from '../../../store/modules/sellers/actions'
import SellerSelector from './SellerSelector'
import getUserType from '../../../hooks/getUserType'
import { SiteSelector } from './SiteSelector'
import type { Tenant } from './types'

const TenantSelector = function () {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { myTenants, selectedTenant } = useTenants()
  const { sites: userSites, isRetailUser } = getUserType()

  const { token, userHasTenant } = useSelector((s: any) => s.application)

  const [init, setInit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tenant, setTenant] = useState<Tenant>({ label: selectedTenant.name, value: selectedTenant.id })
  const [selectOpen, setSelectOpen] = useState(false)
  const [tenants, setTenants] = useState([])
  const remappedTenants = tenants.map((tenant: Tenant) => ({
    value: tenant.value,
    label: tenant.label,
    name: tenant.label,
  }))

  const toggleSelectOpen = () => {
    setSelectOpen((prev) => !prev)
  }
  const closeSelect = () => {
    setSelectOpen(false)
  }

  useEffect(() => {
    if (dataIsLoad(myTenants)) {
      setInit(true)
      const options = myTenants.data.map((t: any) => ({ label: t.name, value: t.id, name: t.label }))
      setTenants(options)
    }
  }, [myTenants])

  useEffect(() => {
    selectedTenant && sellersGetAllAction({ tenant: selectedTenant.id, page: 1, limit: 100 })
  }, [selectedTenant])

  const handleTenantChange = (currentTenant: any) => {
    setTenant(currentTenant)

    setLoading(true)
    if (!loading) {
      getData(token, currentTenant, dispatch, history, setLoading)
    }
    cacheStore(TENANT_SITE_KEY).setData({
      id: currentTenant.value,
      name: currentTenant.label,
      sites: [],
      sellers: [],
    })
  }

  const getTenantSelector = () => {
    if (!userHasTenant) {
      return (
        <Button
          color="warning"
          disabled
        >
          {t('NO_TENANTS_ASSIGNED')}
        </Button>
      )
    }

    return selectedTenant && tenants.length > 0 ? (
      <>
        <Button
          id="menu__dropdown-tenant"
          className="screen-md-view-menu__dropdown menu__dropdown__btn"
          startIcon={
            <Icon
              name="ProjectManagement"
              color="#7569f4"
            />
          }
          onClick={toggleSelectOpen}
        >
          <Typography variant="H200">{tenant.label}</Typography>
        </Button>
        <Select
          options={remappedTenants}
          value={tenant.value}
          onChange={(e) => {
            const selectedValue = e.target.value
            const selectedOption = remappedTenants.find((option) => option.value === selectedValue)
            handleTenantChange(selectedOption)
          }}
          onClose={closeSelect}
          variant="filled"
          open={selectOpen}
          sx={{ visibility: 'hidden', height: 5 }}
        />
      </>
    ) : null
  }

  return (
    <>
      <Grid
        item
        className="tenant_selector"
      >
        {dataIsLoad(myTenants) && getTenantSelector()}
      </Grid>
      <Grid
        item
        className="header__option__menu"
      >
        <SiteSelector
          init={init}
          tenant={tenant}
          isRetailUser={isRetailUser}
          userSites={userSites}
        />
      </Grid>
      <Grid
        item
        className="header__option__menu"
      >
        <SellerSelector
          tenant={tenant}
          init={init}
        />
      </Grid>
    </>
  )
}

export default TenantSelector
