import React from 'react'
import Header from '../../layout/header/Header'
import Layout from '../../layout/Layout'

const App = function ({ children }) {
  const content = () => children

  return (
    <Layout
      content={content}
      header={<Header />}
    />
  )
}

export default App
