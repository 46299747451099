import { Icon, Typography } from '@jarvis-catalyst/jarvis-ui-kit'
import isEmpty from 'lodash.isempty'
import { LocaleString, MenuOptions } from '../../../../store/modules/applications/types'
import AppLink from '../../AppLink'
import ExpandIcon from '../../ExpandIcon'
import { makeItemInternal } from './MakeItemInternal'

type MakeLinkProps = {
    pathName:string,
    item: MenuOptions
    uiSidebar:string,
    translate:(text?: string | LocaleString) => string
}

export const makeLink = ({ item, pathName, translate, uiSidebar }:MakeLinkProps) => {
  const name = translate(item.name)
  const internalItem = makeItemInternal(item, name, uiSidebar)

  if (item.onClick) {
    return (
      <div
        onClick={() => item.onClick && item.onClick()}
        style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%', cursor: 'pointer' }}
      >
        <Icon
          color="#42526E"
          name={item.icon || 'Circle'}
        />
        <Typography variant="h4">{name}</Typography>
      </div>
    )
  }
  if (item.isActive) {
    return (
      <AppLink
        item={item}
      >
        {internalItem}
        {!isEmpty(item.submenu) && (
          <ExpandIcon
            item={item}
            currentPage={pathName}
          />
        )}
      </AppLink>
    )
  }
  return internalItem
}
