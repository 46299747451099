import { useEffect } from 'react'
import { applicationUiSidebarAction, applicationUiTenantDependAction } from '../../../store/modules/application/actions'
import useHasPermissionAndHasSomeScopes from '../../../hooks/useHasPermissionAndHasSomeScopes'
import useTenantsContext from '../contexts/TenantsContext'

export const useTenants = () => {
  const hasWrite = useHasPermissionAndHasSomeScopes({
    scope: 'subscription',
    permission: 'subscription.tenants',
    values: ['write'],
  })

  const { createTenant, updateTenant, deleteTenant } = useTenantsContext()

  useEffect(() => {
    applicationUiSidebarAction('hidden')
    applicationUiTenantDependAction(false)
  }, [])

  return { hasWrite, createTenant, updateTenant, deleteTenant }
}
