import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Box } from '@jarvis-catalyst/jarvis-ui-kit'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Main from './Main'
import Content from './Content'
import './Layout.css'
import Menu from './Menu'

import SidebarFooter from './SidebarFooter'
import { applicationSetConfigurationAction } from '../../store/modules/application/actions'
import { useAppPermissions } from '../../hooks/useAppPermissions'
import { corePermissionsGetAllAction } from '../../store/modules/custom-permissions/actions'
import { getAllCorePermissionsFilter } from '../../utils/filters'
import Help from './help/Help'
import { applicationsGetAllAction, tenantApplicationsGetAllAction } from '../../store/modules/applications/actions'
import { groupsGetAllAction } from '../../store/modules/groups/actions'
import { Group } from '../../store/modules/application/types'
import { cleanString } from '../../utils/stringHelpers'
import { APPLICATION_ACTION_SET_CONFIGURATION } from '../../store/modules/application'
import dictionary from '../error/dictionary'

type LayoutProps = {
  header: React.ReactNode
  content: () => React.ReactNode
}

const DRB_GROUP = 'digital-retail' // TODO: Get active group after PAT-1363

const Layout = function ({ header, content }: LayoutProps) {
  const { token, userHasTenant, activeTenant, language, uiModalOverlay, activeGroup, uiSidebar } = useSelector(
    (s: any) => s.application,
  )
  const { data: coreApps } = useSelector((s: any) => s.applications.getAllByActiveGroup)
  const { data: tenantApps } = useSelector((s: any) => s.applications.getAllTenant)
  const corePermissions = useSelector((s: any) => s.customPermissions.getAllCore)
  const { success } = useSelector((s: any) => s.instrospect.getUserPermissions)
  const { data: groups, success: isLoadedGroups } = useSelector((s: any) => s.groups.getAll)
  const dispatch = useDispatch()

  const permissions = useAppPermissions()
  const location = useLocation()

  useEffect(() => {
    if (token && userHasTenant !== undefined) groupsGetAllAction({ limit: 'all' })
  }, [token, userHasTenant])

  useEffect(() => {
    if (isLoadedGroups && uiSidebar === 'full') {
      // Groups data is required in current UI login flow to load applications
      if (!groups.length) {
        dispatch({
          type: APPLICATION_ACTION_SET_CONFIGURATION,
          payload: {
            key: 'errors',
            value: [dictionary.COD_009_CRASH_DURING_INIT_GROUPS_APP_SERVICE],
          },
        })
      }

      const DRBGroup = groups.filter((group: Group) => cleanString(group.name) === DRB_GROUP)
      if (DRBGroup.length) {
        applicationsGetAllAction({
          limit: 'all',
          groupId: DRBGroup[0].id,
          globalApps: true,
        })
        applicationSetConfigurationAction({
          key: 'activeGroup',
          value: {
            id: DRBGroup[0].id,
            name: DRBGroup[0].name,
            description: DRBGroup[0].description,
            isProtected: DRBGroup[0].isProtected,
          },
        })
      }
    }
  }, [isLoadedGroups, uiSidebar])

  useEffect(() => {
    if (token && activeGroup.id && userHasTenant && uiSidebar === 'full') {
      tenantApplicationsGetAllAction(activeTenant?.id, { limit: 'all' }, activeGroup.id)
    }
  }, [token, userHasTenant, activeTenant, uiSidebar, activeGroup])

  useEffect(() => {
    if (success) corePermissionsGetAllAction(getAllCorePermissionsFilter(1, 100))
  }, [activeTenant, token, success])

  const pName = location.pathname.split('/')
  pName.shift()
  const pathName = `/${pName.join('/')}`

  return (
    // eslint-disable-next-line
    language &&
    activeGroup.id && (
      <Box className={`layout grid-template layout__ui-modal-overlay--${uiModalOverlay}`}>
        <Navbar route={pathName}>{header}</Navbar>
        <Main>
          <Sidebar>
            <Menu
              pathName={pathName}
              coreApps={coreApps}
              permissions={permissions}
              tenantApps={tenantApps}
              corePermissions={corePermissions}
              userHasTenant={userHasTenant}
              activeTenant={activeTenant}
            />
            <SidebarFooter />
          </Sidebar>
          <Content>{content()}</Content>
        </Main>
        <Help />
      </Box>
    )
  )
}

export default Layout
