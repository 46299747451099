import React, { useEffect, useState } from 'react'
import { Classes } from '@jarvis-catalyst/ui-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import menu, { addApps, addAppsHeader, addHome } from './utils/menu'
import { applicationSetConfigurationAction } from '../../store/modules/application/actions'
import { Application, MenuOptions, LocaleString } from '../../store/modules/applications/types'
import { MenuProps } from './layout.types'
import {
  VIEW_MOST_USED_APPS,
  TITLE_VIEW_ALL_APPS,
  TITLE_VIEW_ALL_TENANT_APPS,
  VIEW_MOST_USED_APPS_TENANT,
} from './layaout.constant'
import { makeLink } from './components/Menu/MakeLink'

const Menu = function ({
  pathName,
  coreApps,
  permissions,
  tenantApps,
  userHasTenant,
  corePermissions,
  activeTenant,
}: MenuProps) {
  const { t } = useTranslation()
  const { uiSidebar, language } = useSelector((s: any) => s.application) as { uiSidebar: string; language: 'en' | 'es' }
  const [isGlobalAppsExpanded, setIsGlobalAppsExpanded] = useState(false)
  const [isTenantAppsExpanded, setIsTenantAppsExpanded] = useState(false)

  const translate = (text?: string | LocaleString) => (text && typeof text !== 'string' ? text[language] : t(`${text}`))

  useEffect(() => {
    const storedData = localStorage.getItem('mostUsedApps')
    const mostUsedCoreApps = storedData ? JSON.parse(storedData) : null

    let mostUsedAppsLocalStorage: string[] = []
    if (mostUsedCoreApps) {
      mostUsedAppsLocalStorage = mostUsedCoreApps?.tenants[activeTenant.id]?.apps
    }
    const mostUsedApps = mostUsedAppsLocalStorage
      ? coreApps?.filter((app: Application) => mostUsedAppsLocalStorage.includes(app.id))
      : coreApps

    menu.reset()
    addHome(menu)

    // Process global Apps
    if (isGlobalAppsExpanded) {
      addApps(menu, coreApps, permissions, language)
      menu.add({
        id: VIEW_MOST_USED_APPS,
        icon: 'ChevronUp',
        name: '',
        isActive: true,
        type: 'item',
        onClick: () => setIsGlobalAppsExpanded((prev) => !prev),
      })
    } else if (mostUsedApps.length > 0) {
      addApps(menu, mostUsedApps, permissions, language)
      menu.add({
        id: TITLE_VIEW_ALL_APPS,
        icon: 'ChevronDown',
        name: '',
        isActive: true,
        type: 'item',
        onClick: () => setIsGlobalAppsExpanded(true),
      })
    } else {
      addApps(menu, coreApps, permissions, language)
    }

    // Process Tenant Apps
    if (userHasTenant && !isEmpty(tenantApps)) {
      addAppsHeader(menu)

      const mostUsedTenantApps = mostUsedAppsLocalStorage
        ? tenantApps.filter((app: Application) => mostUsedAppsLocalStorage.includes(app.id))
        : []

      if (isTenantAppsExpanded) {
        addApps(menu, tenantApps, permissions, language)
        menu.add({
          id: 'collapseTenantApps',
          icon: 'ChevronUp',
          name: '',
          isActive: true,
          type: 'item',
          onClick: () => setIsTenantAppsExpanded(false),
        })
        menu.add({
          type: 'divider',
        })
      } else if (mostUsedTenantApps.length > 0) {
        addApps(menu, mostUsedTenantApps, permissions, language)
        menu.add({
          id: TITLE_VIEW_ALL_TENANT_APPS,
          icon: 'ChevronDown',
          name: '',
          isActive: true,
          type: 'item',
          onClick: () => setIsTenantAppsExpanded(true),
        })
        menu.add({
          type: 'divider',
        })
      } else {
        addApps(menu, tenantApps, permissions, language)
      }
    }

    applicationSetConfigurationAction({
      value: menu.build(),
      key: 'availableApplications',
    })
  }, [coreApps, tenantApps, corePermissions, language, userHasTenant, isGlobalAppsExpanded, isTenantAppsExpanded])

  const isCurrentPage = (item: MenuOptions) => item.route && pathName.startsWith(item.route)

  const makeItem = (item: MenuOptions, index: number) => {
    const currentPage = !!isCurrentPage(item)

    if (item.submenu?.some((sub) => isCurrentPage(sub))) {
      item.submenu.map((s) => (s.hidden = false))
    }

    const className = new Classes('layout__sidebar__menu__item')
      .if(currentPage)
      .add('layout__sidebar__menu__item--active')
      .if(!item.isActive && item.type === 'item')
      .add('layout__sidebar__menu__item--disabled')
      .if(item.type === 'divider')
      .add('layout__sidebar__menu__item--divider')
      .if(item.type === 'label')
      .add('layout__sidebar__menu__item--label')
      .if(item.type === 'labelMenu')
      .add('layout__sidebar__menu__item--labelMenu')
    return (
      (currentPage || !item.hidden) && (
        <div
          className={className.build()}
          key={index}
          onClick={() => {
            if (
              item.name !== 'HOME'
              && item.id !== TITLE_VIEW_ALL_APPS
              && item.id !== TITLE_VIEW_ALL_TENANT_APPS
              && item.id !== VIEW_MOST_USED_APPS
              && item.id !== VIEW_MOST_USED_APPS_TENANT
            ) {
              const activeTenantId = activeTenant.id
              const storedData = localStorage.getItem('mostUsedApps')
              const mostUsedApps = storedData ? JSON.parse(storedData) : { tenants: {} }

              if (!mostUsedApps.tenants[activeTenantId]) {
                mostUsedApps.tenants[activeTenantId] = { apps: [] }
              }

              const tenantApps = mostUsedApps.tenants[activeTenantId].apps
              const appExists = tenantApps.some((app: MenuOptions) => app.id === item.id)

              if (!appExists) {
                tenantApps.push(item.id)
                localStorage.setItem('mostUsedApps', JSON.stringify(mostUsedApps))
              }
            }
          }}
        >
          {makeLink({ item, pathName, uiSidebar, translate })}
        </div>
      )
    )
  }

  return <div>{menu.build().map((item: MenuOptions, index: number) => makeItem(item, index))}</div>
}

export default Menu
