import { Typography, Box, Icon, Tooltip } from '@jarvis-catalyst/jarvis-ui-kit'
import { TranslateType as TitleTenantProps } from '../../tenant.type'

export const TitleTenant = function ({ t }:TitleTenantProps) {
  const tooltipTitle = () => (
    <Typography
      variant="H200"
      color="#8B97AD"
      gutterBottom
    >
      <div style={{ display: 'flex', lineHeight: 1.5 }}>{t('MANAGE_TENANTS_TOOLTIP')}</div>
    </Typography>
  )
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
      <Typography variant="H900">
        <div style={{ textDecoration: 'underline', marginRight: 16 }}>{t('MANAGE_TENANTS')}</div>
      </Typography>
      <Tooltip
        data-testid="tooltip-tenant"
        title={tooltipTitle()}
        placement="right-start"
      >
        <Icon
          name="Info"
          color="white"
          size="medium"
          bgcolor="#4a1eeb"
        />
      </Tooltip>
    </Box>
  )
}
