import { MenuOptions } from '../../../../../store/modules/applications/types'

export const jarvisApps: MenuOptions[] = [
  {
    id: '1',
    name: 'Project Management',
    icon: 'ProjectManagement',
    type: 'item',
    route: '/tenants',
    isActive: true,
    description: 'Manage tenants',
    groups: [{ id: 'jarvis', name: 'Jarvis' }],
  },
  {
    id: '2',
    name: 'User Management',
    icon: 'UserManagement',
    type: 'item',
    route: '/users',
    isActive: true,
    description: 'Manage users',
    groups: [{ id: 'jarvis', name: 'Jarvis' }],
  },
  {
    id: '3',
    name: 'RBAC Management',
    icon: 'RBAC',
    type: 'item',
    route: '/users/roles',
    isActive: true,
    description: 'Manage roles',
    groups: [{ id: 'jarvis', name: 'Jarvis' }],
  },
]

export const includesGroup = (obj: any, groupId: string): boolean => {
  let existsGroup = false
  if (obj) {
    Object.values(obj).forEach((g: any) => {
      if (g.id === groupId) {
        existsGroup = true
      }
    })
  }
  return existsGroup
}
