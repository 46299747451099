import { Typography, Breadcrumbs as BreadcrumbsUI } from '@jarvis-catalyst/jarvis-ui-kit'
import { Link } from 'react-router-dom'
import { TranslateType as BreadcrumbsProps } from '../../tenant.type'

export const Breadcrumbs = function ({ t }:BreadcrumbsProps) {
  return (
    <div style={{ marginLeft: 15, marginTop: 4 }}>
      <BreadcrumbsUI>
        <Link
          style={{ textDecoration: 'none', padding: 0 }}
          to={{ pathname: '/' }}
        >
          <Typography
            color="#a7a7a7"
            variant="h4"
          >
            {t('HOME')}
          </Typography>
        </Link>
        <Link
          style={{ textDecoration: 'none', padding: 0 }}
          to={{ pathname: '/tenants' }}
        >
          <Typography
            color="primary"
            variant="h4"
          >
            {t('TENANT')}
          </Typography>
        </Link>
      </BreadcrumbsUI>
    </div>
  )
}
