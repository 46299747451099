import React from 'react'
import {
  CardContent,
  Grid,
  Modal,
  Typography,
  TextField,
  Divider,
  Button,
  ButtonGroup,
  Box,
} from '@jarvis-catalyst/jarvis-ui-kit'
import { Spinner } from '@jarvis-catalyst/ui-components'
import { TenantModalPresenterProps } from './tenantModal.types'

const TenantModalPresenter = function ({
  t,
  heading,
  isModalOpen,
  name,
  description,
  hasWrite,
  isSaved,
  isSaveDisabled,
  error,
  handleModalClose,
  handleSave,
  onNameChange,
  onDescriptionChange,
}: TenantModalPresenterProps) {
  return (
    <Modal open={isModalOpen}>
      <Typography variant="H500">{heading}</Typography>
      <Divider blank />
      <CardContent>
        <Grid>
          <Spinner isCompleting={isSaved} />
          <TextField
            fullWidth
            disabled={!hasWrite}
            placeholder={t('TENANT_NAME_LABEL')}
            label={t('TENANT_NAME_LABEL')}
            value={name}
            onChange={onNameChange}
          />
          <Typography variant="H100">{t('TENANT_NAME_NOTE')}</Typography>
          <Divider blank />
          <TextField
            fullWidth
            multiline
            minRows={5}
            disabled={!hasWrite}
            placeholder={t('DESCRIPTION')}
            value={description}
            onChange={onDescriptionChange}
          />
        </Grid>
      </CardContent>
      <CardContent>
        <Grid item>
          {error.message && (
            <Box sx={{ marginBottom: 3, marginTop: '-15px' }}>
              <Typography
                color="rgb(151, 36, 36)"
                variant="H400"
              >
                {error.property && `${t(error.property.toUpperCase())} `}
                {t(`ERRORS.${error.message}`)}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid
          item
          spacing={2}
        >
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <ButtonGroup size="medium">
              <Button
                variant="text"
                onClick={handleModalClose}
              >
                {t('CANCEL')}
              </Button>
              <Button
                variant="contained"
                onClick={handleSave}
                disabled={isSaveDisabled}
              >
                {t('SAVE')}
              </Button>
            </ButtonGroup>
          </Box>
        </Grid>
      </CardContent>
    </Modal>
  )
}

export default TenantModalPresenter
