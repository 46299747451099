import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography, Link } from '@jarvis-catalyst/jarvis-ui-kit'

export const RightColumn: React.FC = function () {
  const { t } = useTranslation()
  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={2}
      direction="column"
    >
      <Grid
        item
        xs={5}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 2,
            position: 'fixed',
            padding: 1,
            gap: 1.5,
          }}
        >
          <Typography variant="H600">{t('HOMEPAGE.ON_THIS_PAGE')}</Typography>

          <Typography variant="H200">
            <Link
              variant="H200"
              href="#user-roles"
            >
              {t('HOMEPAGE.YOUR_ACC_ROLES')}
            </Link>
          </Typography>
          <Link
            variant="H200"
            href="#featured-apps"
          >
            {t('HOMEPAGE.FEATURED_APPS')}
          </Link>
          <Link
            href="#faq-section"
            variant="H200"
          >
            FAQ
          </Link>
          <Link
            href="#contact-us"
            variant="H200"
          >
            {t('CONTACT')}
          </Link>
        </Box>
      </Grid>
    </Grid>
  )
}
