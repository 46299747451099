import { Dispatch } from 'redux'
import { enhancementAction } from '.'
import { makeRequestFilterPagination } from '../services/services.utils'
import { HTTPRequestCreate, HTTPRequestFilterPagination, HTTPResponsePagination } from '../services/types'
import { ParamsGetAllAction } from './types'
import getObjectProps from '../utils/getObjectProps'

export const dataIsLoad = (d: any) => d.data && !d.loading && d.success
export const createSelector = <T>(dotNotation: string) =>
  (store: any): T =>
    getObjectProps(store, dotNotation)

function createActionGroup(key: string, type: 'CREATE' | 'UPDATE' | 'DELETE' | 'GET_ALL' | 'GET' | 'EXPORT') {
  return {
    START: `${key}_${type}_START`,
    ERROR: `${key}_${type}_ERROR`,
    RESULT: `${key}_${type}_RESULT`,
    RESET: `${key}_${type}_RESET`,
  }
}

export const createGetAllReducer = (key: string) => {
  const type = 'GET_ALL'
  const actions = createActionGroup(key, type)
  const initialState = {
    data: [],
    pagination: { total: 0, pageSize: 0, page: 0, totalPages: 0 },
    error: null,
    success: null,
    errorMessage: '',
    loading: false,
  }
  const reducer = (prevState = initialState, action: any) => {
    switch (action.type) {
    case actions.START:
      return { ...prevState, data: prevState.data || [], success: null, error: null, loading: true }
    case actions.RESET:
      return {
        ...prevState,
        data: initialState.data,
        success: null,
        error: null,
        loading: false,
        pagination: initialState.pagination,
      }
    case actions.RESULT:
      return {
        ...prevState,
        success: true,
        error: false,
        data: action.payload.data,
        pagination: action.payload.meta.pagination,
        loading: false,
      }
    case actions.ERROR:
      return { ...prevState, success: false, error: true, errorMessage: action.payload, loading: false }
    default:
      return prevState
    }
  }
  return { actions, reducer }
}

export const createGetReducer = (
  key: string,
  initialState: any = {
    data: null,
    error: null,
    success: null,
    errorMessage: '',
    loading: false,
  },
) => {
  const type = 'GET'
  const actions = createActionGroup(key, type)
  const reducer = (prevState = initialState, action: any) => {
    switch (action.type) {
    case actions.START:
      return { ...prevState, data: initialState.data, success: null, error: null, loading: true }
    case actions.RESET:
      return { ...prevState, data: initialState.data, success: null, error: null, loading: false }
    case actions.RESULT:
      return { ...prevState, success: true, error: false, data: action.payload, loading: false }
    case actions.ERROR:
      return { ...prevState, success: false, error: true, errorMessage: action.payload, loading: false }
    default:
      return prevState
    }
  }
  return { actions, reducer }
}

export const createCommitReducer = (
  key: string,
  type: 'CREATE' | 'UPDATE' | 'DELETE' | 'EXPORT',
  initialState: any = {
    data: null,
    error: null,
    success: null,
    errorMessage: '',
    loading: false,
  },
) => {
  const actions = createActionGroup(key, type)
  const reducer = (prevState = initialState, action: any) => {
    switch (action.type) {
    case actions.START:
      return { ...prevState, data: initialState.data, success: null, error: null, loading: true }
    case actions.RESET:
      return { ...prevState, data: initialState.data, success: null, error: null, loading: false }
    case actions.RESULT:
      return { ...prevState, success: true, error: false, data: action.payload, loading: false }
    case actions.ERROR:
      return { ...prevState, success: false, error: true, errorMessage: action.payload, loading: false }
    default:
      return prevState
    }
  }
  return { actions, reducer }
}

// Actions
export const getAction = (actionStore: any, service: any) =>
  (id: string): void =>
    enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
      const { token, activeTenant } = getState().application
      const { actions } = actionStore
      dispatch({ type: actions.START, payload: null })
      try {
        const request = { id, token, tenant: activeTenant.id }
        const data: any = await service(request)
        dispatch({ type: actions.RESULT, payload: data })
      } catch (error) {
        dispatch({ type: actions.ERROR, payload: error })
      }
    })

export const resetAction = (actionStore: any) => (): void =>
  enhancementAction(async (dispatch: Dispatch) => {
    dispatch({ type: actionStore.actions.RESET, payload: null })
  })

export const getAllAction = (actionStore: any, service: any) =>
  (params?: ParamsGetAllAction): void =>
    enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
      const { token } = getState().application
      dispatch({ type: actionStore.actions.START, payload: null })
      try {
        const request: HTTPRequestFilterPagination = makeRequestFilterPagination(token, params)
        if (params) {
          request.tenant = params.tenant
        }
        const data: HTTPResponsePagination<any[]> = await service(request)
        dispatch({ type: actionStore.actions.RESULT, payload: data })
      } catch (error) {
        dispatch({ type: actionStore.actions.ERROR, payload: error })
      }
    })

export const createAction = (actionStore: any, service: any) =>
  (data: any): void =>
    enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
      const { token } = getState().application
      dispatch({ type: actionStore.actions.START, payload: null })
      try {
        const request: HTTPRequestCreate<any> = { token, data }
        const result: any = await service(request)
        if (result.errors && result.errors.length > 0) {
          dispatch({ type: actionStore.actions.ERROR, payload: result.errors[0].title })
        } else {
          dispatch({ type: actionStore.actions.RESULT, payload: result })
        }
      } catch (error) {
        dispatch({ type: actionStore.actions.ERROR, payload: error })
      }
    })

export const updateAction = (actionStore: any, service: any) =>
  (id: string, data: any): void =>
    enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
      const { token } = getState().application
      dispatch({ type: actionStore.actions.START, payload: null })
      try {
        const request: any = { token, id, data }
        const result: any = await service(request)
        if (result.errors && result.errors.length > 0) {
          dispatch({
            type: actionStore.actions.ERROR,
            payload: result.errors[0].title,
          })
        } else {
          dispatch({ type: actionStore.actions.RESULT, payload: result })
        }
      } catch (error) {
        dispatch({ type: actionStore.actions.ERROR, payload: error })
      }
    })

export const deleteAction = (actionStore: any, service: any) =>
  (id: any): void =>
    enhancementAction(async (dispatch: Dispatch, getState: () => any) => {
      const { token } = getState().application
      dispatch({ type: actionStore.actions.START, payload: null })
      try {
        const request: any = { token, id }
        const result: any = await service(request)
        if (result.errors && result.errors.length > 0) {
          dispatch({ type: actionStore.actions.ERROR, payload: result.errors[0].title })
        } else {
          dispatch({ type: actionStore.actions.RESULT, payload: result })
        }
      } catch (error) {
        dispatch({ type: actionStore.actions.ERROR, payload: error })
      }
    })

export const getLocalStorageLanguaje = (): 'es' | 'en' => {
  const languageData = localStorage.getItem('language')
  return languageData ? JSON.parse(languageData).data : 'es'
}
