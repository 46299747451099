/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { Link, LinkProps, useLocation } from 'react-router-dom'
import { applicationResetCustomAppUrl } from '../../../store/modules/application/actions'

type LinkWrapperProps = LinkProps & {onClick?:()=>void}

const LinkWrapper = function ({ children, ...props }:LinkWrapperProps) {
  const location = useLocation()
  const locationcomplete = location.pathname + location.search
  const handlerClick = () => {
    if (locationcomplete !== props.to) {
      applicationResetCustomAppUrl()
    }
  }
  return (
    <Link
      {...props}
      onClick={() => {
        props.onClick && props.onClick()
        handlerClick()
      }}
    >
      {children}
    </Link>
  )
}

export default LinkWrapper
