import React, { useState, useEffect } from 'react'
import { Button, Icon, Typography } from '@jarvis-catalyst/jarvis-ui-kit'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import type { StoreAllData } from '../../../store/types'
import { applicationSetConfigurationAction } from '../../../store/modules/application/actions'
import cacheStore from '../../../utils/cache-store'
import { TENANT_SITE_KEY } from '../../../utils/cache-store/types'
import { userPermissionsGetAction } from '../../../store/modules/user-permissions/actions'
import SelectSiteModal from './SelectSiteModal'
import './SellerSelector.css'
import type { Site, Tenant } from './types'

type SiteSelectorProps = {
  init: boolean
  tenant: Tenant
  isRetailUser: boolean
  userSites: Array<any>
}

export const SiteSelector: React.FC<SiteSelectorProps> = function ({ init, tenant, isRetailUser, userSites }) {
  const history = useHistory()
  const { t } = useTranslation()
  const [showDialog, setShowDialog] = useState(false)
  const { activeTenant, userHasTenant } = useSelector((s: any) => s.application)
  const siteStore: StoreAllData<Site> = useSelector((state: any) => state.sites.getAll)

  const getSelectedSites = () => {
    const query = new URLSearchParams(window.location.search)
    let sites: string[] = []
    const qSites = query.get('sites')
    if (qSites != null) {
      sites = decodeURIComponent(qSites).split(',')
    }
    const validSites = siteStore.data.filter(
      (e: Site) => userSites.find((s) => s.key === e.key) && sites.includes(e.key),
    )
    return validSites.map((e: Site) => e.name)
  }

  const handleConfirmSite = (sites: Site[]) => {
    if (init) {
      const sitesQs = `${sites.reduce((prev: string, curr: Site) => `${prev},${curr.key}`, '')}`.replace(',', '')

      const query = new URLSearchParams(window.location.search)
      query.set('tenant', tenant.value)
      query.set('sites', sitesQs)
      query.set('seller', activeTenant.sellers[0]?.key || '')

      applicationSetConfigurationAction({
        key: 'activeTenant',
        value: { id: tenant.value, name: tenant.label, sites, sellers: activeTenant.sellers },
      })

      history.push({ search: query.toString() })
      cacheStore(TENANT_SITE_KEY).setData({
        id: tenant.value,
        name: tenant.label,
        sites,
        sellers: activeTenant.sellers,
      })
      userPermissionsGetAction()
      setShowDialog(false)
    }
  }

  useEffect(() => {
    if (userHasTenant && isRetailUser) {
      if (userSites.length === 1) {
        handleConfirmSite(userSites)
        return
      }

      const sitesSelected = activeTenant.sites?.length !== 0
        && userSites.find((us) => activeTenant.sites?.find((site: Site) => site.key === us.key))
      if (!sitesSelected) setShowDialog(true)
    }
  }, [isRetailUser])

  const renderIcon = () => {
    if (siteStore.loading) {
      return (
        <Icon
          name="Loading"
          color="#7569f4"
          size="small"
        />
      )
    }
    return (
      <Icon
        name="Site"
        color="#7569f4"
        size="small"
      />
    )
  }

  const renderEndIcon = () => {
    if (getSelectedSites().length === 0) {
      return (
        <Icon
          name="ChevronDown"
          color="#7569f4"
        />
      )
    }
    return null
  }

  const renderSelector = () => {
    if (siteStore.data.length === 0) {
      return (
        <Button
          id="menu__dropdown-seller"
          className="screen-md-view-menu__dropdown menu__dropdown__btn"
          color="secondary"
          startIcon={renderIcon()}
          disabled
        >
          <Typography variant="H200">{t('MANAGE_SITE.WITHOUT_SITE')}</Typography>
        </Button>
      )
    }

    return (
      <>
        {siteStore.data.length > 0 && showDialog && (
          <SelectSiteModal
            dataSites={siteStore as any}
            tenant={tenant}
            onCancel={() => setShowDialog(false)}
            onConfirm={handleConfirmSite as any}
            isRetailUser={isRetailUser}
            userSites={userSites}
          />
        )}
        <Button
          id="menu__dropdown-seller"
          className="screen-md-view-menu__dropdown menu__dropdown__btn"
          startIcon={renderIcon()}
          endIcon={renderEndIcon()}
          color="primary"
          variant="outlined"
          disabled={siteStore.loading}
          onClick={() => !siteStore.loading && setShowDialog(true)}
        >
          <Typography variant="H200">
            {getSelectedSites().length > 0 ? getSelectedSites().join(', ') : t('MANAGE_SITE.SITE_DEFAULT')}
          </Typography>
        </Button>
      </>
    )
  }

  return <>{renderSelector()}</>
}
