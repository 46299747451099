import React, { useEffect, useState } from 'react'
import isEmpty from 'lodash.isempty'
import { useTranslation } from 'react-i18next'

import { TenantModalProps } from '../../tenant.type'
import TenantModalPresenter from './TenantModalPresenter'

const TenantModal = function ({
  handleModalClose,
  handleSaveClick,
  heading,
  isModalOpen,
  tenant,
  hasWrite = false,
}: TenantModalProps) {
  const { t } = useTranslation()

  const [name, setName] = useState<string>(tenant.name || '')
  const [description, setDescription] = useState<string>(tenant.description || '')
  const [isSaved, setIsSaved] = useState<boolean>(true)
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true)
  const [error, setError] = useState<{ message: string; property?: string }>({ message: '' })

  useEffect(() => {
    setIsSaveDisabled(isEmpty(name) || isEmpty(description))
  }, [name, description])

  const handleSave = async () => {
    setIsSaved(false)
    try {
      await handleSaveClick({
        ...tenant,
        name,
        description,
      })
      setIsSaved(true)
      handleModalClose()
    } catch (err: any) {
      setIsSaved(true)
      setError({
        message: err.message || 'UNKNOWN_ERROR',
        property: err.property || '',
      })
    }
  }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError({ message: '' })
    setName(e.target.value)
  }

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value)
  }

  return (
    <TenantModalPresenter
      t={t}
      heading={heading}
      isModalOpen={isModalOpen}
      name={name}
      description={description}
      hasWrite={hasWrite}
      isSaved={isSaved}
      isSaveDisabled={isSaveDisabled}
      error={error}
      handleModalClose={handleModalClose}
      handleSave={handleSave}
      onNameChange={onNameChange}
      onDescriptionChange={onDescriptionChange}
    />
  )
}

export default TenantModal
