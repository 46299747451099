import React, { useState, useEffect } from 'react'
import isEmpty from 'lodash.isempty'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  Box,
  Grid,
  Navbar,
  IconButton,
  Avatar,
  Typography,
  Menu,
  Button,
  FloatingActionButton,
  Icon,
} from '@jarvis-catalyst/jarvis-ui-kit'
import LogoFalabella from '../components/FalabellaIcon'
import './Header.css'

import { getParams, navigateToUrl, replaceParams } from '../../shell/utils/router'
import {
  applicationSetConfigurationAction,
  applicationUiSidebarAction,
} from '../../../store/modules/application/actions'
import TenantSelector from '../manage-tenant-site/TenantSelector'
import { DEFAULT_LANG, DEFAULT_URI_START_APP, LANGS } from '../../../const'
import useAppendQS from '../../../hooks/useAppendQS'
import useAuthenticationContextWrapper from '../../shell/contexts/AuthenticationContextWrapper'
import cacheStore from '../../../utils/cache-store'
import { LANGUAGE_KEY } from '../../../utils/cache-store/types'
import EnvironmentIdentifier from './EnvironmentIdentifier'
import { getMinName } from '../utils/getNameForAvatar'

const getLocale = (history: any) => {
  const state = getParams(history)
  if (isEmpty(LANGS.find((t) => t.value === state.locale))) {
    const storedLanguage = cacheStore(LANGUAGE_KEY).getData()
    state.locale = isEmpty(storedLanguage) ? DEFAULT_LANG.value : storedLanguage
    replaceParams(history, state)
  }
  return state.locale
}

const Header = function () {
  const { errors, uiTenantDepend, uiModalOverlay, uiSidebar } = useSelector((s: any) => s.application)
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const appendQS = useAppendQS()
  const [locale, setLocale] = useState<any>(() => getLocale(history))
  const [isToggleMenu, setIsToggleMenu] = useState(false)

  const { logoutToLogin, userDetails, authorizationError }: any = useAuthenticationContextWrapper()

  const hasError = !isEmpty(authorizationError)

  useEffect(() => {
    if (history.action === 'POP') setLocale(getLocale(history))
  }, [history, history.location.search])

  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [i18n, locale])

  const handleChange = (value: { locale: any }) => {
    replaceParams(history, value)
    applicationSetConfigurationAction({ key: 'language', value: value.locale })
    i18n.changeLanguage(value.locale)
    cacheStore(LANGUAGE_KEY).setData(value.locale)
    setLocale(value.locale)
  }

  const toggleSidebar = (uiSidebar: string) => () => {
    if (uiSidebar === 'full') applicationUiSidebarAction('compact', true)
    if (uiSidebar === 'compact' || uiSidebar === 'hidden') applicationUiSidebarAction('full', true)
  }

  const handleToggle = () => {
    setIsToggleMenu(!isToggleMenu)
  }

  useEffect(() => {
    if (uiModalOverlay === 'show') {
      setIsToggleMenu(false)
    }
  }, [uiModalOverlay])

  return (
    <Navbar
      toolbarProps={{
        IconButton: uiTenantDepend ? IconButton : undefined,
        IconButtonProps: uiTenantDepend
          ? { name: 'MenuBurger', onClick: toggleSidebar(uiSidebar), sx: { color: '#ffffff' } }
          : {},
        menuIcon: uiTenantDepend,
      }}
    >
      <Grid
        container
        direction="row"
        className="header-container"
        sx={{ pl: 0 }}
      >
        <Grid item>
          <Box className="header__logo">
            <a
              href="/"
              id="menu__link-logo"
              className="header__menu__link_logo"
              style={{ width: 146, height: 56, display: 'flex', paddingTop: 5 }}
            >
              <LogoFalabella />
            </a>
            <EnvironmentIdentifier />
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!uiTenantDepend ? (
              <Box
                className="header__menu"
                sx={{ marginRight: 2 }}
              >
                <FloatingActionButton
                  variant="circular"
                  color="info"
                  name="ChevronLeft"
                  size="small"
                  onClick={() => {
                    setIsToggleMenu(false)
                    applicationUiSidebarAction('full', true)
                    history.push(appendQS(DEFAULT_URI_START_APP))
                  }}
                />
              </Box>
            ) : (
              !hasError && <TenantSelector />
            )}
            <Box className="header__notification header__option__menu">
              <Button
                id="locale-button"
                onClick={() => handleChange({ locale: locale === 'es' ? 'en' : 'es' })}
                className="screen-md-view-menu__dropdown menu__dropdown__lenguage"
              >
                {locale.toLocaleUpperCase()}
              </Button>
              <Box sx={{ marginLeft: 1 }}>
                <Menu
                  id="mainMenu-avatar"
                  AnchorComponent={Button}
                  AnchorComponentProps={{
                    id: 'mainMenu-button',
                    children: (
                      <div className="avatar-content">
                        <Avatar
                          sx={{ color: '#7569F4', bgcolor: 'white' }}
                          size={40}
                          id="main-avatar"
                        >
                          <Typography variant="H200">{getMinName(userDetails.name).toUpperCase()}</Typography>
                        </Avatar>
                      </div>
                    ),
                  }}
                  contents={[
                    {
                      content: (
                        <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 'bolder' }}>
                          <Typography variant="h3">{userDetails.name}</Typography>
                          <Typography variant="h4">{userDetails.email}</Typography>
                        </div>
                      ),
                      disabled: true,
                    },
                    {
                      content: <Typography variant="H300">{t('TENANT_MANAGEMENT_LABEL')}</Typography>,
                      onClick: () => {
                        navigateToUrl(history, '/tenants')
                      },
                      disabled: errors.length > 0,
                    },
                    {
                      content: <Typography variant="H300">{t('USER_AND_PERMISSIONS')}</Typography>,
                      onClick: () => {
                        setIsToggleMenu(false)
                        navigateToUrl(history, '/users')
                      },
                      disabled: errors.length > 0,
                    },
                    {
                      content: <Typography variant="H300">{t('APPLICATIONS')}</Typography>,
                      onClick: () => {
                        setIsToggleMenu(false)
                        navigateToUrl(history, '/applications')
                      },
                      disabled: errors.length > 0,
                    },
                    {
                      content: <Typography variant="H300">{t('ACTIVITY_LOG')}</Typography>,
                      onClick: () => {
                        setIsToggleMenu(false)
                        navigateToUrl(history, '/logs')
                      },
                      disabled: errors.length > 0,
                    },
                    {
                      content: <Typography variant="H300">{t('LOGOUT')}</Typography>,
                      onClick: async () => {
                        setIsToggleMenu(false)
                        await logoutToLogin()
                      },
                      disabled: errors.length > 0,
                    },
                  ]}
                />
              </Box>
            </Box>
            <div
              onClick={handleToggle}
              data-testid="header__menu-controller"
              className={`header__menu-controller header__menu-controller-${isToggleMenu ? 'open' : 'close'}`}
            >
              <Icon
                name="MenuBurger"
                color="#ffffff"
              />
            </div>
          </Box>
        </Grid>
      </Grid>
    </Navbar>
  )
}

export default Header
